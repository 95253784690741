import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Four Ways a Smile Makeover Can Change Your Life | The Smile Stylist" 
    description="A new beautiful smile can bring you the joy and confidence to chase your dreams and smile without cringing. Book your consult at The Smile Stylist and let us help." 
    keywords="Smile Makeover" />
    <main className="site-content prindex" role="main">
    <section id="main-top-img" className="blog hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <div className="blog-news-art">
              <h2>Is Your Smile Compromising Your Online Dating Life?</h2>
              <h2>Why your teeth are #1 when it comes to the game of love…</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="main-top-img" className="blog hidden-sm hidden-md hidden-lg">
      <div className="container">
        <div className="row">
          <p>&nbsp;</p>
        </div>
      </div>
    </section>
    <section id="top-mobile" className="hidden-sm hidden-md hidden-lg">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <h1>Four Ways a Smile Makeover Can Change Your Life</h1>
            <h2>What are you missing out on?</h2>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight">
            <img src={ require("../../images/desktop/_blog/pics/july2017/july2017-sm.jpg" )} className="img-thumbnail img-news hidden-md hidden-lg" />
            <p className="lead">People who have transformed their smiles with a smile makeover notice just how dramatically their lives have changed after their procedure. A great smile not only enhances a person’s look, but it also has the power to open you up to new and exciting life experiences.</p>
            <br />
            <p>Here are four ways that a stunning smile can change your life:</p>

            <h2>1. It can make you more likeable, and attract more friends.</h2>
            <p>A person who is confident in their smile is more likely to smile more… and it’s been proven time and time again that we’re naturally drawn to smiley people. Smiles are inviting and evolutionarily contagious… so by wearing a confident smile, your circle of friends is bound to grow bigger and bigger! Oh, and like we mentioned in our last blog, almost three quarters of a group studied considered themselves more likely to trust someone with a nice smile compared to a nice outfit, job, or vehicle… so a confident smile just may be the most important asset you own.</p>
            <h2>2. It can give you the confidence you’ve always dreamed of.</h2>
            <p>Having a picture-perfect smile can truly boost a person’s self-esteem by a ton… and our Toronto cosmetic dentist has seen it time and time again. When a person feels good about how they look, it automatically shows in their demeanor. Confident people are viewed as more successful, which leads us to our next point…</p>
            <h2>3. It can open you up to more success. </h2>
            <p>It’s a proven fact: people with better smiles are more successful. They get hired at a much more frequent rate, and are more likely to excel in their careers compared to people with crooked or unsightly smiles. What has your smile done for your professional life?</p>
            <h2>4. Those double takes? Yeah, they weren’t for your friend.</h2>
            <p>A perfect smile can light up a face like nothing else, and this is sure to get you the right kind of attention. A smile has such a huge influence on a person’s beauty or attractiveness… why not look the best you can with a life-changing smile makeover?</p>
          </div>
          <div className="col-sm-4 blog-right">
            <img src={ require("../../images/desktop/_blog/pics/july2017/july2017-side2.jpg" )} className="img-responsive wow animated zoomIn" data-wow-delay="0.4s" /><br />
            <img src={ require("../../images/desktop/_blog/pics/july2017/july2017-side1.jpg" )} className="img-responsive wow animated zoomIn" data-wow-delay="0.6s" />
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage